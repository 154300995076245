@import 'normalize.css';

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizeSpeed;
    background-color: #f5f5f5;
}
